import './projects.scss';

function Projects() {
    return (<div className="projects"><h1>Projects</h1>
        <br />
        <h3>Admin Panel</h3>
        <div className="pcard">
            <p>
                <strong>Web Panel:&nbsp;</strong>
                <a target="_blank" href="http://soroush.live">Soroush.live</a>
            </p>
            <p>
                <strong>Rest Api:&nbsp;</strong>
                <a target="_blank" href=" http://duna.ems.security.admin.api.soroush.live">Soroush.live Api</a>
            </p>
            <p> • Note: for login use these username=admin, password=123</p>
        </div>

        <h3>Nuget Libraries</h3>
        <div className="pcard">
            <p>
                <a target="_blank" href="https://www.nuget.org/packages/Duna.Libs.Web">Duna.Libs.Web</a>
                <span>&nbsp;- Useful Library to write Web Apis very fast with minimum code base, including all CRUD implementation.</span>
            </p>
            <p> <a target="_blank" href="https://www.nuget.org/packages/Duna.Libs.Utils">Duna.Libs.Utils</a>
                <span>&nbsp;- Useful library to help developers with some utilities</span>
            </p>
            <p>
                <a target="_blank" href="https://www.nuget.org/packages/Duna.Libs.Core">Duna.Libs.Core</a>
                <span>&nbsp;- Core library, implemented some base infrastructures and patterns for the other libraries</span>
            </p>
        </div>
    </div >);
}

export default Projects;