import './skills.css';

function Skills() {
    return (<div className="skills">
        <h2>Skills</h2>
        <div className="pcard">
            <ul>
                <li>Full-Stack</li>
                <li>back-end</li>
                <li>front-end</li>
                <li>Requirement analysis</li>
                <li>Solution design</li>
                <li>Software architecture</li>
                <li>Testing</li>
                <li>Deployment</li>
                <li>Data Model</li>
                <li>Problem Solving</li>
                <li>Leadership</li>
                <li>Documentation</li>
            </ul>
        </div>
        <h3>Technical Skills</h3>
        <div className="pcard">
            <ul>
                <li><strong>Programming:</strong> C# | .net | C / C++ | JavaScript | TypeScript | Python | Java | NodeJs | html | css | sass | Vue.js | React | jQuery | bootstrap | Asp.net | REST | Api | WCF | Web Services | soap | XML | csv | gRpc | proto | VB | php | Kendo UI | Telerik.</li>
                <li><strong>Database:</strong> MSSQL | MySQL | Oracle | Redis | MongoDB | Cloud SQL | AWS RDS | Azure DB | Entity Framework.</li>
                <li><strong>DevOps:</strong> Git CICD | Docker | Kubernetes | IIS | Google cloud | AWS | Azure | tomcat.</li>
                <li><strong>Methodologies & Tools:</strong> Microservices | UML | SOLID | OOP | SOP | SAAS | MVC | MVVM | Design Patterns | TDD | ORM | RDB Normalization | Denormalization | Distributed Systems | Multi - Layer Design | Security Principles | Agile | Scrum | Kanban | SAF(Scaled Agile Framework) | OKR | STAR | RUP | Git | P4 | TFS | Jira | Technical diagrams | Confluence | Grafana | Cryptography | RSA | NuGet | helm | RabbitMQ | Swagger | NSawg | Postman | ssh | Office | GDI + | OpenGL | DirectX | Unity3D | Frostbite | Scimitar | Ubisoft Anvil | PS5 | PS4 | XGDK | XONE | XBSX | Google Stadia.</li>
                <li><strong>Enthusiastic to learn:</strong> Go | Graph QL | ML | LLM | SLM | Scala | Kafka | Ruby on Rails</li>

            </ul>
        </div>
    </div>);
}

export default Skills;