import './App.css';
import './style.scss';
import React, { useState, useEffect } from "react";
import Experiences from './experiences/experiences.jsx';
import Skills from './skills/skills.jsx';
import Projects from './projects/projects.jsx';

function App() {

    const [status, setStatus] = useState("Submit");
    const [visitedCount, setVisitedCount] = useState("visitedCount");
    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus("Sending...");
        console.log(e);
        const { txtName, txtEmail, txtSubject, txtMessage } = e.target.elements;
        let details = {
            name: txtName.value,
            email: txtEmail.value,
            subject: txtSubject.value,
            message: txtMessage.value,
        };
        let response = await fetch("/contactus", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(details),
        });
        setStatus("Submit");
        let result = await response.json();
        alert(result.status);
    };

    const handleLoad = async (e) => {
        let response = await fetch("/visit", {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
            //body: JSON.stringify(details),
        });
        let result = await response.json();
        setVisitedCount(result);
    }

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        const script1 = document.createElement('script');
        script1.src = "./scripts.js";
        script1.async = true;
        document.body.appendChild(script1);
        // Add event listener for document load
        window.addEventListener('load', handleLoad);


        //const script2 = document.createElement('script');
        //script2.src = "./js/glsample8.js";
        //script2.async = true;
        //document.body.appendChild(script2);

        //const script3 = document.createElement('script');
        //script3.src = "./js/gldraw.js";
        //script3.async = true;
        //document.body.appendChild(script3);



        return () => {
            document.body.removeChild(script1);
            //document.body.removeChild(script2);
            //document.body.removeChild(script3);
            window.removeEventListener('load', handleLoad);
        }
    });

    return (
        <div id="fullpage">
            <div className="section" id="section0">

                {/* <canvas id="glcanvas" width="800" height="480"></canvas>*/}
                {/* <div className="fp-bg"></div> */}
                <h1><span id="s0-soroush">Soroush</span> <br /><span id="s0-asadzadeh">Asadzadeh</span></h1>
                <h3>Senior Software Developer | Team Lead</h3>
                
            </div>
            <div className="section" id="section1-1">
                {/* <div className="fp-bg"></div> */}
                <Skills />
            </div>
            <div className="section" id="section1-2">
                {/* <div className="fp-bg"></div> */}
                <h2>Professional Experience</h2>
                <Experiences />
            </div>
            <div className="section" id="section2">
                {/* <div className="fp-bg"></div> */}
                <Projects />
            </div>
            <div className="section" id="section3">
                {/* <div className="fp-bg"></div> */}
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1>Contact me</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5">
                            <div className="row">
                                <div className="col-12">
                                    <a target='_blank' href="mailto:info@SoroushAsadzadeh.com">
                                        <span className="fa-stack fa-2x"><i className="fas fa-square fa-stack-2x"></i><i className="fa-stack-1x fa-solid fa-envelope fa-fade fa-inverse"></i></span>
                                    </a>
                                    <a target='_blank' href="http://www.instagram.com/soroush.public">
                                        <span className="fa-stack fa-2x"><i className="fas fa-square fa-stack-2x"></i><i className="fa-stack-1x fa-brands fa-instagram fa-fade fa-inverse"></i></span>
                                    </a>
                                    <a target='_blank' href="https://www.linkedin.com/in/soroushasadzadeh/">
                                        <span className="fa-stack fa-2x"><i className="fas fa-square fa-stack-2x"></i><i className="fa-stack-1x fa-brands fa-linkedin-in fa-fade fa-inverse"></i></span>
                                    </a>
                                </div>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="txtName">Name</label>
                                            <input type="text" className="form-control" id="txtName" placeholder="Name" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="txtEmail">Email Address</label>
                                            <input type="email" className="form-control" id="txtEmail" aria-describedby="emailHelp" placeholder="Your Email Address" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="txtSubject">Subject</label>
                                            <input type="text" className="form-control" id="txtSubject" placeholder="Subject" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="txtMessage">Message</label>
                                            <textarea className="form-control" id="txtMessage" rows="5"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <button id='btnSend' type="submit" className="btn btn-primary mb-2 btn-block">{status === "Submit" ? <i className="fas fa-fw fa-paper-plane"></i> : <i className="fas fa-fw fa-loading"></i>}{status}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="viewed">
                    <span className="fa-stack fa-1x">
                        <i className="fas fa-square fa-stack-2x"></i>
                        <i className="fa-stack-1x fa fa-eye"></i>
                    </span>
                    <span>{visitedCount.requestLoadCount}</span>
                    <br />
                    <span className="fa-stack fa-1x">
                        <i className="fas fa-square fa-stack-2x"></i>
                        <i className="fa-stack-1x fa fa-user"></i>
                    </span>
                    <span>{visitedCount.clientCount}</span>
                </div>
            </div>
        </div >

    );
}

export default App;
