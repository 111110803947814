import Experience from './experience.jsx';
import data from './experiences.json';
import './experiences.scss';

function experiences() {
    var exps = data.map((obj, ix) => <Experience key={obj.order}
        company={obj.company}
        location={obj.location}
        dtfrom={obj.dtfrom}
        dtto={obj.dtto}
        title={obj.title}
        type={obj.type}
        descriptions={obj.descriptions} />);
    return (
        <div className="experience-list">{exps}</div>);
}


export default experiences;