import './experiences.scss';

function experience({
    company,
    title,
    dtfrom,
    dtto,
    location,
    type,
    descriptions
}) {

    let desc;
    if (descriptions.length > 1) {
        const temp = descriptions.map((content, ix) => <li key={ix}><p>{content}</p></li>);
        desc = <ul>{temp}</ul>;
    }
    else {
        desc = <p>{descriptions}</p>;
    }

    return (
        <div className="experience pcard">
            <div className="company">{company}</div>
            <div className="location">{location}</div>
            <div className="type">{type}</div>
            <div className="dt">{dtfrom} – {dtto}</div>
            <div className="title">{title}</div>
            <div className="description">{desc}</div>
        </div>
    );
}

export default experience;